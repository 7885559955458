import brunch from "../Images/Icons/brunch.png";
import url from "../Images/Icons/url.png";
import mainImg from "../Images/Contents/Tumbling/tumbling_main.png";

import { updateHeader } from "./Data_Func";

const TumblingHeader = {
  stage: "Detail",
  path: "tumbling",
  title: "텀블링",
  desc: (
    <>
      오늘도 우리는 텀블러를 든다!<p></p>하나의 텀블러를 오래 사용하도록
      유도하는 앱
    </>
  ),
  channelArray: [
    {
      url: "https://brunch.co.kr/@vicotorlee/181",
      platform: brunch,
    },
  ],
  mainImg: mainImg,
  tags: [],
  lastUpdate: { date: null, title: null, index: null },
  introArray: [],
};

const TumblingContents = [
  {
    date: "2023/07/01",
    title: "텀블링 앱 출시",
    desc: ["텀블링 앱 기획", "텀블링 제작기 브런치 작성"],
    tags: ["기획"],
    channelArray: [
      // {
      //   url: "https://apps.apple.com/kr/app/%ED%85%80%EB%B8%94%EB%A7%81/id6444554979",
      //   platform: url,
      // },
      {
        url: "https://brunch.co.kr/@vicotorlee/181",
        platform: brunch,
      },
    ],
  },
];

updateHeader(TumblingHeader, TumblingContents);

export { TumblingHeader, TumblingContents };
