import download from "../Images/Icons/download.png";
import brunch from "../Images/Icons/brunch.png";
import mainImg from "../Images/Contents/Supernova/supernova_main.png";
import img1 from "../Images/Contents/Supernova/supernova_1.png";
import { updateHeader } from "./Data_Func";

const SupernovaHeader = {
  stage: "Detail",
  path: "supernova",
  title: "초신성",
  desc: <>가상 현실과 인공지능을 소재로 한 SF 소설</>,
  channelArray: [
    {
      url: "https://brunch.co.kr/brunchbook/supernova",
      platform: brunch,
    },
  ],
  mainImg: mainImg,
  tags: [],
  lastUpdate: { date: null, title: null, index: null },
  introArray: [],
};

const SupernovaContents = [
  {
    date: "2023/06/11",
    img: img1,
    title: "초신성 브런치북 출간",
    desc: ["소설 초신성 퇴고", "소설 초신성 브런치북 출간"],
    tags: ["작가"],
    channelArray: [
      {
        platform: brunch,
        url: "https://brunch.co.kr/brunchbook/supernova",
      },
    ],
  },
  {
    date: "2018/03/05",
    title: "초신성 집필",
    desc: ["소설 초신성 집필"],
    tags: ["작가"],
    channelArray: [
      {
        url: "/Files/chaoxinxing.pdf",
        platform: download,
        type: "download",
      },
    ],
  },
];

updateHeader(SupernovaHeader, SupernovaContents);

export { SupernovaHeader, SupernovaContents };
