import brunch from "../Images/Icons/brunch.png";
import instagram from "../Images/Icons/instagram.png";
import mainImg from "../Images/Contents/Nbp/nbp_main.png";
import url from "../Images/Icons/url.png";
import img1 from "../Images/Contents/Nbp/nbp_1.png";
import img2 from "../Images/Contents/Nbp/nbp_2.png";
import img3 from "../Images/Contents/Nbp/nbp_3.png";
import img4 from "../Images/Contents/Nbp/nbp_4.png";
import img5 from "../Images/Contents/Nbp/nbp_5.png";
import img6 from "../Images/Contents/Nbp/nbp_6.png";

import { updateHeader } from "./Data_Func";

const NbpHeader = {
  stage: "Detail",
  path: "nbp",
  title: "출판전야",
  desc: (
    <>
      고독한 몽상가를 위한<p></p>쉼표와 마침표가 있는 은신처
    </>
  ),
  channelArray: [
    {
      url: "https://nightbeforepublishing.com/",
      platform: url,
    },
    {
      url: "https://www.instagram.com/night.before.publishing/",
      platform: instagram,
    },
  ],
  mainImg: mainImg,
  tags: [],
  lastUpdate: { date: null, title: null, index: null },
  introArray: [],
};

const NbpContents = [
  {
    date: "2025/01/02",
    img: img6,
    ratio: "1:1",
    title: "목요 작가 프로그램 진행",
    desc: ["목요 작가 프로그램에 정현지 작가 님 참여"],
    tags: ["기획", "콘텐츠"],
    channelArray: [
      {
        url: "https://nightbeforepublishing.com/#/writer",
        platform: url,
      },
      {
        url: "https://www.instagram.com/_junghyunjee/",
        platform: instagram,
      },
    ],
  },
  {
    date: "2024/09/01",
    img: img5,
    ratio: "1:1",
    title: "목요 작가 프로그램 시작",
    desc: ["목요 작가 프로그램에 유유 출판사 참여"],
    tags: ["기획", "콘텐츠"],
    channelArray: [
      {
        url: "https://nightbeforepublishing.com/#/writer",
        platform: url,
      },
      {
        url: "https://www.instagram.com/uupress/",
        platform: instagram,
      },
    ],
  },
  {
    date: "2024/08/19",
    img: img4,
    ratio: "1:1",
    title: "월요 작가 프로그램 시작",
    desc: ["월요 작가 프로그램에 김 로로 작가 님 참여"],
    tags: ["기획", "콘텐츠"],
    channelArray: [
      {
        url: "https://nightbeforepublishing.com/#/writer",
        platform: url,
      },
      {
        url: "https://www.instagram.com/loro__s/",
        platform: instagram,
      },
    ],
  },
  {
    date: "2024/05/13",
    img: img3,
    ratio: "16:9",
    title: "출판전야 오픈",
    desc: [
      "고독의 서재, 출판전야 예약 개시",
      "두문즉시심산(杜門卽是深山) 집필",
    ],
    tags: ["기획", "콘텐츠", "작가"],
    channelArray: [
      {
        url: "https://booking.naver.com/booking/12/bizes/1133745",
        platform: url,
      },
      {
        url: "https://brunch.co.kr/brunchbook/the-eve",
        platform: brunch,
      },
    ],
  },
  {
    date: "2022/04/17",
    img: img2,
    ratio: "16:9",
    title: "출판전야 웹사이트 오픈",
    desc: ["출판전야 웹사이트 개발", "출판전야 웹사이트 제작기 작성"],
    tags: ["기획", "디자인", "개발", "콘텐츠"],
    channelArray: [
      {
        url: "https://nightbeforepublishing.com/",
        platform: url,
      },
      {
        url: "https://brunch.co.kr/@vicotorlee/185",
        platform: brunch,
      },
    ],
  },
  {
    date: "2022/02/21",
    img: img1,
    ratio: "16:9",
    title: "출판전야 인사이트 아카이브",
    desc: ["인스타그램 채널 개설", "인스타그램 채널 운영"],
    tags: ["콘텐츠"],
    channelArray: [
      {
        url: "https://www.instagram.com/night.before.publishing/",
        platform: instagram,
      },
    ],
  },
];

updateHeader(NbpHeader, NbpContents);

export { NbpHeader, NbpContents };
